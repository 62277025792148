import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F7EFEF',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '1px solid #FF6900',
    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '15px',
    paddingLeft: '10px',
    '&:focus': {
      borderRadius: 4,
      transform: 'translate(1px)',
      borderColor: '#FF6900',
      borderWidth: '2px',
      backgroundColor: '#F7EFEF',
      height: '15px'
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#F7EFEF',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '5px',
      top: '0',
      color: '#FF6900'
    },
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput />}
          {...props}
        >
          <option aria-label="Nenhum" value="" />
          {
            props.data.map((item) => {
              return (<option value={item.VALUE}>{item.DISPLAY}</option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}