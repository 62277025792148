import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'
import Tabs from '../components/Tabs';

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function RegPacientes(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboTerapeuta, setComboTerapeuta] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboCidade, setComboCidade] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboSede, setComboSede] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboEstudo, setComboEstudo] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboTipoCad, setComboTipoCad] = useState([{ VALUE: 'A', DISPLAY: 'Adulto' }, { VALUE: 'I', DISPLAY: 'Infantil' }, { VALUE: 'F', DISPLAY: 'Familia/Casal' }])
  const [comboComoConheceu, setComboComoConheceu] = useState([{ VALUE: '', DISPLAY: '' }])
  const [comboSimNao, setComboSimNao] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])
  const [comboMasFem, setComboMasFem] = useState([{ VALUE: 'M', DISPLAY: 'Masculino' }, { VALUE: 'F', DISPLAY: 'Feminino' }])
  const [enableEdit, setEnableEdit] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('pacientes', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('pacientes', 'Inclusao'))
      }
      setComboCidade(await api.getComboData('cidade'))
      setComboTerapeuta(await api.getComboData('terapeuta'))
      
      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/pacientes?pk_pac=' + query.codigo+'&usu_logado='+auth.user).then(r => {
          console.log(query.codigo)
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'descricao',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/pacientes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/pacientes/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = data[e.target.name];
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Visualização de Pacientes</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
        {data?.TIPO_CADASTRO == 'C' ? 
          <Tabs aba1_caption="Paciente 1" aba1_hidden={false} aba2_caption="Paciente 2" aba2_hidden={false} aba3_hidden aba4_hidden aba5_hidden aba6_hidden aba7_hidden Aba1={
            <div>
          <div >
                     
                      <br/>

          <FrTextInput
            maxLength='100'
            value={data.NOME || ''}
            onChange={handleChange}
            name="NOME"
            id="NOME"
            color="#389492"
            label="Nome"
            
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
           <FrDatePicker
            value={(data.DATA_NASC || '').split('T')[0]}
            name="DATA_NASC"
            id="DATA_NASC"
            color="#528b46"
            readOnly
            label="Data Nascimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}

          />
           <FrSelect
            value={data.SEXO || ''}
            style={{ width: 130 }}
            name="SEXO"
            id="SEXO"
            
            label='Sexo'
            data={comboMasFem}
            onChange={handleChange}
          /> 
          
          <FrTextInput
            maxLength='100'
            value={data.CPF || ''}
            onChange={handleChange}
            name="CPF"
            id="CPF"
            
            color="#389492"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />
          <br/>
           <FrTextInput
            maxLength='12'
            value={data.FONE_CELULAR || ''}
            onChange={handleChange}
            name="FONE_CELULAR"
            id="FONE_CELULAR"
            color="#389492"
            
            label="Celular"
            variant="outlined"
            size="small"
            
            style={{ width: 180 }}
          />

           <FrTextInput
            maxLength='100'
            value={data.EMAIL || ''}
            onChange={handleChange}
            name="EMAIL"
            id="EMAIL"
            color="#389492"
            
            label="E-mail"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />

           <FrTextInput
            maxLength='100'
            value={data.FACEBOOK || ''}
            onChange={handleChange}
            name="FACEBOOK"
            id="FACEBOOK"
            color="#389492"
            
            label="Facebook"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />
           <FrTextInput
            maxLength='100'
            value={data.RELIGIAO || ''}
            onChange={handleChange}
            name="RELIGIAO"
            id="RELIGIAO"
            color="#389492"
            
            label="Religião"
            variant="outlined"
            size="small"
            
            style={{ width: 220 }}
          />
          <br/>

          <FrSelect
            value={data.FK_CAD_TERAPEUTA || ''}
            style={{ width: 230 }}
            name="FK_CAD_TERAPEUTA"
            
            id="FK_CAD_TERAPEUTA"
            label='Como Conheceu InTCC'
            data={comboComoConheceu}
            onChange={handleChange}
          /> 

          <FrSelect
            value={data.FK_CID_NATURALIDADE || ''}
            style={{ width: 330 }}
            name="FK_CID_NATURALIDADE"
            
            id="FK_CID_NATURALIDADE"
            label='Naturalidade'
            data={comboCidade}
            onChange={handleChange}
          /> 

          <FrTextInput
            maxLength='100'
            value={data.CEP || ''}
            onChange={handleChange}
            name="CEP"
            id="CEP"
            color="#389492"
            
            label="CEP"
            variant="outlined"
            size="small"
            
            style={{ width: 150 }}
          />

          <FrSelect
            value={data.FK_CID_RESIDENCIAL || ''}
            style={{ width: 330 }}
            name="FK_CID_RESIDENCIAL"
            
            id="FK_CID_RESIDENCIAL"
            label='Cidade'
            data={comboCidade}
            onChange={handleChange}
          /> 


          <FrTextInput
            maxLength='100'
            value={data.ENDERECO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="ENDERECO_RESIDENCIAL"
            id="ENDERECO_RESIDENCIAL"
            color="#389492"
            
            label="Endereço"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.COMPLEMENTO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="COMPLEMENTO_RESIDENCIAL"
            id="COMPLEMENTO_RESIDENCIAL"
            color="#389492"
            
            label="Complemento"
            variant="outlined"
            size="small"
            
            style={{ width: 220 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.BAIRRO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="BAIRRO_RESIDENCIAL"
            id="BAIRRO_RESIDENCIAL"
            color="#389492"
            
            label="Bairro"
            variant="outlined"
            size="small"
            
            style={{ width: 290 }}
          />
          <FrSelect
            value={data.FK_SED_PREFERENCIAL || ''}
            style={{ width: 230 }}
            name="FK_SED_PREFERENCIAL"
            
            id="FK_SED_PREFERENCIAL"
            label='Sede Preferencial'
            data={comboSede}
            onChange={handleChange}
          /> 
          <br/>

          <FrTextInput
            maxLength='100'
            value={data.COM_QUEM_MORA || ''}
            onChange={handleChange}
            name="COM_QUEM_MORA"
            id="COM_QUEM_MORA"
            color="#389492"
            
            label="Com Quem Mora"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />

          <FrSelect
            value={data.ESTUDA_ATUALMENTE || ''}
            style={{ width: 190 }}
            name="ESTUDA_ATUALMENTE"
            
            id="ESTUDA_ATUALMENTE"
            label='Estuda Atualmente'
            data={comboSimNao}
            onChange={handleChange}
          /> 
          <FrSelect
            value={data.NIVEL_ESTUDO || ''}
            style={{ width: 200 }}
            name="NIVEL_ESTUDO"
            
            id="NIVEL_ESTUDO"
            label='Nível Estudo'
            data={comboEstudo}
            onChange={handleChange}
          /> 

          <FrTextInput
            maxLength='100'
            value={data.SEMESTRE_UNIVERSIDADE || ''}
            onChange={handleChange}
            name="SEMESTRE_UNIVERSIDADE"
            id="SEMESTRE_UNIVERSIDADE"
            color="#389492"
            
            label="Semestre"
            variant="outlined"
            size="small"
            
            style={{ width: 100 }}
          />

          <FrSelect
            value={data.FK_CAD_TERAPEUTA || ''}
            style={{ width: 180 }}
            name="FK_CAD_TERAPEUTA"
            
            id="FK_CAD_TERAPEUTA"
            label='Gênero Terapeuta'
            data={comboMasFem}
            onChange={handleChange}
          /> 
          
          <FrSelect
            value={data.EMITE_NOTA || ''}
            style={{ width: 180 }}
            name="EMITE_NOTA"
            
            id="EMITE_NOTA"
            label='Emite Nota'
            data={comboSimNao}
            onChange={handleChange}
          /> 
          
          <FrSelect
            value={data.TIPO_CADASTRO || ''}
            style={{ width: 220 }}
            name="TIPO_CADASTRO"
            id="TIPO_CADASTRO"
            label='Tipo Cadastro'
            data={comboTipoCad}
            onChange={handleChange}
          /> 
          </div>
        </div>
        }
        Aba2={
          <div>
            <div >
                       
                        <br/>
  
            <FrTextInput
              maxLength='100'
              value={data.NOME_M2 || ''}
              onChange={handleChange}
              name="NOME_M2"
              id="NOME_M2"
              color="#389492"
              label="Nome"
              
              variant="outlined"
              size="small"
              style={{ width: 330 }}
            />
             <FrDatePicker
              value={(data.DATA_NASC_M2 || '').split('T')[0]}
              name="DATA_NASC_M2"
              id="DATA_NASC_M2"
              color="#528b46"
              readOnly
              label="Data Nascimento"
              variant="outlined"
              size="small"
              style={{ width: 190 }}
  
            />
             <FrSelect
              value={data.SEXO_M2 || ''}
              style={{ width: 130 }}
              name="SEXO_M2"
              id="SEXO_M2"
              
              label='Sexo'
              data={comboMasFem}
              onChange={handleChange}
            /> 
            
            <FrTextInput
              maxLength='100'
              value={data.CPF_M2 || ''}
              onChange={handleChange}
              name="CPF_M2"
              id="CPF_M2"
              
              color="#389492"
              label="CPF"
              variant="outlined"
              size="small"
              style={{ width: 180 }}
            />
            <br/>
             <FrTextInput
              maxLength='12'
              value={data.FONE_CELULAR_M2 || ''}
              onChange={handleChange}
              name="FONE_CELULAR_M2"
              id="FONE_CELULAR_M2"
              color="#389492"
              
              label="Celular"
              variant="outlined"
              size="small"
              
              style={{ width: 180 }}
            />
  
             <FrTextInput
              maxLength='100'
              value={data.EMAIL_M2 || ''}
              onChange={handleChange}
              name="EMAIL_M2"
              id="EMAIL_M2"
              color="#389492"
              
              label="E-mail"
              variant="outlined"
              size="small"
              
              style={{ width: 420 }}
            />
  
             <FrTextInput
              maxLength='100'
              value={data.FACEBOOK_M2 || ''}
              onChange={handleChange}
              name="FACEBOOK_M2"
              id="FACEBOOK_M2"
              color="#389492"
              
              label="Facebook"
              variant="outlined"
              size="small"
              
              style={{ width: 420 }}
            />
             {/* <FrTextInput
              maxLength='100'
              value={data.RELIGIAO_M2 || ''}
              onChange={handleChange}
              name="RELIGIAO_M2"
              id="RELIGIAO_M2"
              color="#389492"
              
              label="Religião"
              variant="outlined"
              size="small"
              
              style={{ width: 220 }}
            /> */}
            <br/>
  
            <FrSelect
              value={data.FK_CAD_TERAPEUTA || ''}
              style={{ width: 230 }}
              name="FK_CAD_TERAPEUTA"
              
              id="FK_CAD_TERAPEUTA"
              label='Como Conheceu InTCC'
              data={comboComoConheceu}
              onChange={handleChange}
            /> 
  
  
            <FrTextInput
              maxLength='100'
              value={data.CEP_RESIDENCIAL_M2 || ''}
              onChange={handleChange}
              name="CEP_RESIDENCIAL_M2"
              id="CEP_RESIDENCIAL_M2"
              color="#389492"
              
              label="CEP"
              variant="outlined"
              size="small"
              
              style={{ width: 150 }}
            />
  
            <FrSelect
              value={data.FK_CID_RESIDENCIAL_M2 || ''}
              style={{ width: 330 }}
              name="FK_CID_RESIDENCIAL_M2"
              
              id="FK_CID_RESIDENCIAL_M2"
              label='Cidade'
              data={comboCidade}
              onChange={handleChange}
            /> 
  
  
            <FrTextInput
              maxLength='100'
              value={data.ENDERECO_RESIDENCIAL_M2 || ''}
              onChange={handleChange}
              name="ENDERECO_RESIDENCIAL_M2"
              id="ENDERECO_RESIDENCIAL_M2"
              color="#389492"
              
              label="Endereço"
              variant="outlined"
              size="small"
              
              style={{ width: 420 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.COMPLEMENTO_RESIDENCIAL_M2 || ''}
              onChange={handleChange}
              name="COMPLEMENTO_RESIDENCIAL_M2"
              id="COMPLEMENTO_RESIDENCIAL_M2"
              color="#389492"
              
              label="Complemento"
              variant="outlined"
              size="small"
              
              style={{ width: 220 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.BAIRRO_RESIDENCIAL_M2 || ''}
              onChange={handleChange}
              name="BAIRRO_RESIDENCIAL_M2"
              id="BAIRRO_RESIDENCIAL_M2"
              color="#389492"
              
              label="Bairro"
              variant="outlined"
              size="small"
              
              style={{ width: 290 }}
            />
            <FrSelect
              value={data.FK_SED_PREFERENCIAL || ''}
              style={{ width: 230 }}
              name="FK_SED_PREFERENCIAL"
              
              id="FK_SED_PREFERENCIAL"
              label='Sede Preferencial'
              data={comboSede}
              onChange={handleChange}
            /> 
            <br/>
  
            <FrTextInput
              maxLength='100'
              value={data.COM_QUEM_MORA || ''}
              onChange={handleChange}
              name="COM_QUEM_MORA"
              id="COM_QUEM_MORA"
              color="#389492"
              
              label="Com Quem Mora"
              variant="outlined"
              size="small"
              
              style={{ width: 420 }}
            />
  
         
            
            <FrSelect
              value={data.TIPO_CADASTRO || ''}
              style={{ width: 220 }}
              name="TIPO_CADASTRO"
              id="TIPO_CADASTRO"
              label='Tipo Cadastro'
              data={comboTipoCad}
              onChange={handleChange}
            /> 
            </div>
          </div>
          
      }
        />
        :
        data?.TIPO_CADASTRO == 'I' ?
        <Tabs aba1_caption="Paciente" aba1_hidden={false} aba2_caption="Responsável 1" aba3_caption="Responsável 2" aba2_hidden={false} aba3_hidden={false} aba4_hidden aba5_hidden aba6_hidden aba7_hidden Aba1={
          <div>
        <div >
                   
                    <br/>

        <FrTextInput
          maxLength='100'
          value={data.NOME || ''}
          onChange={handleChange}
          name="NOME"
          id="NOME"
          color="#389492"
          label="Nome"
          
          variant="outlined"
          size="small"
          style={{ width: 330 }}
        />
         <FrDatePicker
          value={(data.DATA_NASC || '').split('T')[0]}
          name="DATA_NASC"
          id="DATA_NASC"
          color="#528b46"
          readOnly
          label="Data Nascimento"
          variant="outlined"
          size="small"
          style={{ width: 190 }}

        />
         <FrSelect
          value={data.SEXO || ''}
          style={{ width: 130 }}
          name="SEXO"
          id="SEXO"
          
          label='Sexo'
          data={comboMasFem}
          onChange={handleChange}
        /> 
        
        <FrTextInput
          maxLength='100'
          value={data.CPF || ''}
          onChange={handleChange}
          name="CPF"
          id="CPF"
          
          color="#389492"
          label="CPF"
          variant="outlined"
          size="small"
          style={{ width: 180 }}
        />
        <br/>
         <FrTextInput
          maxLength='12'
          value={data.FONE_CELULAR || ''}
          onChange={handleChange}
          name="FONE_CELULAR"
          id="FONE_CELULAR"
          color="#389492"
          
          label="Celular"
          variant="outlined"
          size="small"
          
          style={{ width: 180 }}
        />

         <FrTextInput
          maxLength='100'
          value={data.EMAIL || ''}
          onChange={handleChange}
          name="EMAIL"
          id="EMAIL"
          color="#389492"
          
          label="E-mail"
          variant="outlined"
          size="small"
          
          style={{ width: 420 }}
        />

         <FrTextInput
          maxLength='100'
          value={data.FACEBOOK || ''}
          onChange={handleChange}
          name="FACEBOOK"
          id="FACEBOOK"
          color="#389492"
          
          label="Facebook"
          variant="outlined"
          size="small"
          
          style={{ width: 420 }}
        />
         <FrTextInput
          maxLength='100'
          value={data.RELIGIAO || ''}
          onChange={handleChange}
          name="RELIGIAO"
          id="RELIGIAO"
          color="#389492"
          
          label="Religião"
          variant="outlined"
          size="small"
          
          style={{ width: 220 }}
        />
        <br/>

        <FrSelect
          value={data.FK_CAD_TERAPEUTA || ''}
          style={{ width: 230 }}
          name="FK_CAD_TERAPEUTA"
          
          id="FK_CAD_TERAPEUTA"
          label='Como Conheceu InTCC'
          data={comboComoConheceu}
          onChange={handleChange}
        /> 

        <FrSelect
          value={data.FK_CID_NATURALIDADE || ''}
          style={{ width: 330 }}
          name="FK_CID_NATURALIDADE"
          
          id="FK_CID_NATURALIDADE"
          label='Naturalidade'
          data={comboCidade}
          onChange={handleChange}
        /> 

        <FrTextInput
          maxLength='100'
          value={data.CEP || ''}
          onChange={handleChange}
          name="CEP"
          id="CEP"
          color="#389492"
          
          label="CEP"
          variant="outlined"
          size="small"
          
          style={{ width: 150 }}
        />

        <FrSelect
          value={data.FK_CID_RESIDENCIAL || ''}
          style={{ width: 330 }}
          name="FK_CID_RESIDENCIAL"
          
          id="FK_CID_RESIDENCIAL"
          label='Cidade'
          data={comboCidade}
          onChange={handleChange}
        /> 


        <FrTextInput
          maxLength='100'
          value={data.ENDERECO_RESIDENCIAL || ''}
          onChange={handleChange}
          name="ENDERECO_RESIDENCIAL"
          id="ENDERECO_RESIDENCIAL"
          color="#389492"
          
          label="Endereço"
          variant="outlined"
          size="small"
          
          style={{ width: 420 }}
        />
        <FrTextInput
          maxLength='100'
          value={data.COMPLEMENTO_RESIDENCIAL || ''}
          onChange={handleChange}
          name="COMPLEMENTO_RESIDENCIAL"
          id="COMPLEMENTO_RESIDENCIAL"
          color="#389492"
          
          label="Complemento"
          variant="outlined"
          size="small"
          
          style={{ width: 220 }}
        />
        <FrTextInput
          maxLength='100'
          value={data.BAIRRO_RESIDENCIAL || ''}
          onChange={handleChange}
          name="BAIRRO_RESIDENCIAL"
          id="BAIRRO_RESIDENCIAL"
          color="#389492"
          
          label="Bairro"
          variant="outlined"
          size="small"
          
          style={{ width: 290 }}
        />
        <FrSelect
          value={data.FK_SED_PREFERENCIAL || ''}
          style={{ width: 230 }}
          name="FK_SED_PREFERENCIAL"
          
          id="FK_SED_PREFERENCIAL"
          label='Sede Preferencial'
          data={comboSede}
          onChange={handleChange}
        /> 
        <br/>

        <FrTextInput
          maxLength='100'
          value={data.COM_QUEM_MORA || ''}
          onChange={handleChange}
          name="COM_QUEM_MORA"
          id="COM_QUEM_MORA"
          color="#389492"
          
          label="Com Quem Mora"
          variant="outlined"
          size="small"
          
          style={{ width: 420 }}
        />

        <FrSelect
          value={data.ESTUDA_ATUALMENTE || ''}
          style={{ width: 190 }}
          name="ESTUDA_ATUALMENTE"
          
          id="ESTUDA_ATUALMENTE"
          label='Estuda Atualmente'
          data={comboSimNao}
          onChange={handleChange}
        /> 
        <FrSelect
          value={data.NIVEL_ESTUDO || ''}
          style={{ width: 200 }}
          name="NIVEL_ESTUDO"
          
          id="NIVEL_ESTUDO"
          label='Nível Estudo'
          data={comboEstudo}
          onChange={handleChange}
        /> 

        <FrTextInput
          maxLength='100'
          value={data.SEMESTRE_UNIVERSIDADE || ''}
          onChange={handleChange}
          name="SEMESTRE_UNIVERSIDADE"
          id="SEMESTRE_UNIVERSIDADE"
          color="#389492"
          
          label="Semestre"
          variant="outlined"
          size="small"
          
          style={{ width: 100 }}
        />

        <FrSelect
          value={data.FK_CAD_TERAPEUTA || ''}
          style={{ width: 180 }}
          name="FK_CAD_TERAPEUTA"
          
          id="FK_CAD_TERAPEUTA"
          label='Gênero Terapeuta'
          data={comboMasFem}
          onChange={handleChange}
        /> 
        
        <FrSelect
          value={data.EMITE_NOTA || ''}
          style={{ width: 180 }}
          name="EMITE_NOTA"
          
          id="EMITE_NOTA"
          label='Emite Nota'
          data={comboSimNao}
          onChange={handleChange}
        /> 
        
        <FrSelect
          value={data.TIPO_CADASTRO || ''}
          style={{ width: 220 }}
          name="TIPO_CADASTRO"
          id="TIPO_CADASTRO"
          label='Tipo Cadastro'
          data={comboTipoCad}
          onChange={handleChange}
        /> 
        </div>
      </div>
      }
      Aba2={
        <div>
          <div >
                     
          <br/>

          <FrTextInput
            maxLength='100'
            value={data.NOME_RESP1 || ''}
            onChange={handleChange}
            name="NOME_RESP1"
            id="NOME_RESP1"
            color="#389492"
            label="Nome"
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.CPF_RESP1 || ''}
            onChange={handleChange}
            name="CPF_RESP1"
            id="CPF_RESP1"
            color="#389492"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
          
          <FrTextInput
            maxLength='100'
            value={data.IDADE_RESP1 || ''}
            onChange={handleChange}
            name="IDADE_RESP1"
            id="IDADE_RESP1"
            color="#389492"
            label="Idade"
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
          
          <FrTextInput
            maxLength='100'
            value={data.FONE_CELULAR_RESP1 || ''}
            onChange={handleChange}
            name="FONE_CELULAR_RESP1"
            id="FONE_CELULAR_RESP1"
            color="#389492"
            label="Fone Celular"
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
          </div>
        </div>
        
    }
    Aba3={
      <div>
        <div >
                   
        <br/>

        <FrTextInput
          maxLength='100'
          value={data.NOME_RESP2 || ''}
          onChange={handleChange}
          name="NOME_RESP2"
          id="NOME_RESP2"
          color="#389492"
          label="Nome"
          variant="outlined"
          size="small"
          style={{ width: 330 }}
        />
        <FrTextInput
          maxLength='100'
          value={data.CPF_RESP2 || ''}
          onChange={handleChange}
          name="CPF_RESP2"
          id="CPF_RESP2"
          color="#389492"
          label="CPF"
          variant="outlined"
          size="small"
          style={{ width: 330 }}
        />
        
        <FrTextInput
          maxLength='100'
          value={data.IDADE_RESP2 || ''}
          onChange={handleChange}
          name="IDADE_RESP2"
          id="IDADE_RESP2"
          color="#389492"
          label="Idade"
          variant="outlined"
          size="small"
          style={{ width: 330 }}
        />
        
        <FrTextInput
          maxLength='100'
          value={data.FONE_CELULAR_RESP2 || ''}
          onChange={handleChange}
          name="FONE_CELULAR_RESP2"
          id="FONE_CELULAR_RESP2"
          color="#389492"
          label="Fone Celular"
          variant="outlined"
          size="small"
          style={{ width: 330 }}
        />
        </div>
      </div>
      
  }
      />
        :
        <>

          <FrTextInput
            maxLength='100'
            value={data.NOME || ''}
            onChange={handleChange}
            name="NOME"
            id="NOME"
            color="#389492"
            label="Nome"
            
            variant="outlined"
            size="small"
            style={{ width: 330 }}
          />
          <FrDatePicker
            value={(data.DATA_NASC || '').split('T')[0]}
            name="DATA_NASC"
            id="DATA_NASC"
            color="#528b46"
            readOnly
            label="Data Nascimento"
            variant="outlined"
            size="small"
            style={{ width: 190 }}

          />
          <FrSelect
            value={data.SEXO || ''}
            style={{ width: 130 }}
            name="SEXO"
            id="SEXO"
            
            label='Sexo'
            data={comboMasFem}
            onChange={handleChange}
          /> 

          <FrTextInput
            maxLength='100'
            value={data.CPF || ''}
            onChange={handleChange}
            name="CPF"
            id="CPF"
            
            color="#389492"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />
          <br/>
          <FrTextInput
            maxLength='12'
            value={data.FONE_CELULAR || ''}
            onChange={handleChange}
            name="FONE_CELULAR"
            id="FONE_CELULAR"
            color="#389492"
            
            label="Celular"
            variant="outlined"
            size="small"
            
            style={{ width: 180 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.EMAIL || ''}
            onChange={handleChange}
            name="EMAIL"
            id="EMAIL"
            color="#389492"
            
            label="E-mail"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.FACEBOOK || ''}
            onChange={handleChange}
            name="FACEBOOK"
            id="FACEBOOK"
            color="#389492"
            
            label="Facebook"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.RELIGIAO || ''}
            onChange={handleChange}
            name="RELIGIAO"
            id="RELIGIAO"
            color="#389492"
            
            label="Religião"
            variant="outlined"
            size="small"
            
            style={{ width: 220 }}
          />
          <br/>

          <FrSelect
            value={data.FK_CAD_TERAPEUTA || ''}
            style={{ width: 230 }}
            name="FK_CAD_TERAPEUTA"
            
            id="FK_CAD_TERAPEUTA"
            label='Como Conheceu InTCC'
            data={comboComoConheceu}
            onChange={handleChange}
          /> 

          <FrSelect
            value={data.FK_CID_NATURALIDADE || ''}
            style={{ width: 330 }}
            name="FK_CID_NATURALIDADE"
            
            id="FK_CID_NATURALIDADE"
            label='Naturalidade'
            data={comboCidade}
            onChange={handleChange}
          /> 

          <FrTextInput
            maxLength='100'
            value={data.CEP || ''}
            onChange={handleChange}
            name="CEP"
            id="CEP"
            color="#389492"
            
            label="CEP"
            variant="outlined"
            size="small"
            
            style={{ width: 150 }}
          />

          <FrSelect
            value={data.FK_CID_RESIDENCIAL || ''}
            style={{ width: 330 }}
            name="FK_CID_RESIDENCIAL"
            
            id="FK_CID_RESIDENCIAL"
            label='Cidade'
            data={comboCidade}
            onChange={handleChange}
          /> 


          <FrTextInput
            maxLength='100'
            value={data.ENDERECO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="ENDERECO_RESIDENCIAL"
            id="ENDERECO_RESIDENCIAL"
            color="#389492"
            
            label="Endereço"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.COMPLEMENTO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="COMPLEMENTO_RESIDENCIAL"
            id="COMPLEMENTO_RESIDENCIAL"
            color="#389492"
            
            label="Complemento"
            variant="outlined"
            size="small"
            
            style={{ width: 220 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.BAIRRO_RESIDENCIAL || ''}
            onChange={handleChange}
            name="BAIRRO_RESIDENCIAL"
            id="BAIRRO_RESIDENCIAL"
            color="#389492"
            
            label="Bairro"
            variant="outlined"
            size="small"
            
            style={{ width: 290 }}
          />
          <FrSelect
            value={data.FK_SED_PREFERENCIAL || ''}
            style={{ width: 230 }}
            name="FK_SED_PREFERENCIAL"
            
            id="FK_SED_PREFERENCIAL"
            label='Sede Preferencial'
            data={comboSede}
            onChange={handleChange}
          /> 
          <br/>

          <FrTextInput
            maxLength='100'
            value={data.COM_QUEM_MORA || ''}
            onChange={handleChange}
            name="COM_QUEM_MORA"
            id="COM_QUEM_MORA"
            color="#389492"
            
            label="Com Quem Mora"
            variant="outlined"
            size="small"
            
            style={{ width: 420 }}
          />

          <FrSelect
            value={data.ESTUDA_ATUALMENTE || ''}
            style={{ width: 190 }}
            name="ESTUDA_ATUALMENTE"
            
            id="ESTUDA_ATUALMENTE"
            label='Estuda Atualmente'
            data={comboSimNao}
            onChange={handleChange}
          /> 
          <FrSelect
            value={data.NIVEL_ESTUDO || ''}
            style={{ width: 200 }}
            name="NIVEL_ESTUDO"
            
            id="NIVEL_ESTUDO"
            label='Nível Estudo'
            data={comboEstudo}
            onChange={handleChange}
          /> 

          <FrTextInput
            maxLength='100'
            value={data.SEMESTRE_UNIVERSIDADE || ''}
            onChange={handleChange}
            name="SEMESTRE_UNIVERSIDADE"
            id="SEMESTRE_UNIVERSIDADE"
            color="#389492"
            
            label="Semestre"
            variant="outlined"
            size="small"
            
            style={{ width: 100 }}
          />

          <FrSelect
            value={data.FK_CAD_TERAPEUTA || ''}
            style={{ width: 180 }}
            name="FK_CAD_TERAPEUTA"
            
            id="FK_CAD_TERAPEUTA"
            label='Gênero Terapeuta'
            data={comboMasFem}
            onChange={handleChange}
          /> 

          <FrSelect
            value={data.EMITE_NOTA || ''}
            style={{ width: 180 }}
            name="EMITE_NOTA"
            
            id="EMITE_NOTA"
            label='Emite Nota'
            data={comboSimNao}
            onChange={handleChange}
          /> 

          <FrSelect
            value={data.TIPO_CADASTRO || ''}
            style={{ width: 220 }}
            name="TIPO_CADASTRO"
            id="TIPO_CADASTRO"
            label='Tipo Cadastro'
            data={comboTipoCad}
            onChange={handleChange}
          /> 
          </>
        }

        </form>

        <div className="regButtonsDiv">
          {/* {enableEdit ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)} */}
          <button onClick={handleCancel}>{1==0 ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegPacientes;
