import React, { useState, createContext } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import {profile} from 'react-icons-kit/icomoon/profile'
import { ic_library_books } from 'react-icons-kit/md/ic_library_books'
import { ic_build } from 'react-icons-kit/md/ic_build'

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import icon from '../../imgs/logo_intcc.png';
import logo from '../../imgs/logo_intcc.png';
import { NavBarLink } from '../NavBarLink';
import UserOptions from '../UserOptions';
import {fileSymlinkFile} from 'react-icons-kit/oct/fileSymlinkFile'
import { ic_event_available } from 'react-icons-kit/md/ic_event_available';
import {calendar} from 'react-icons-kit/oct/calendar'

export const NavContext = createContext({
    hide: true
})

function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openAdmissao, setOpenAdmissao] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openLocalizacao, setOpenLocalizacao] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openInternacoes, setOpenInternacoes] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [openFinanceiro2, setOpenFinanceiro2] = useState(false)
    const [openExames, setOpenExames] = useState(false);


    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenAdmissao(false)
            setOpenSistema(false)
            setOpenAuxiliares(false)
        }

        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenCadastros(!openCadastros)

    }

    function abreAdmissao(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAdmissao(!openAdmissao)

    }
    function abreSistema(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenSistema(!openSistema)

    }

    return (
        <NavContext.Provider value={{hide}}>

            <div className={"Navbar" + (hide ? '-hide' : '')}>
                <button onClick={toggleNavBarHide} className="toggle_navbar">
                    {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
                </button>
                <Nav justify defaultActiveKey="/intcc/home" className="flex-column">
                    <Tooltip title="Tela Principal">
                        <Nav.Link href="/intcc/home">
                            <div className={'divLogo' + (hide? '-hide': '')}>
                                {hide ? <img alt='Expandir' src={icon} className="fr_icon_navbar" /> : <img alt='Encolher' src={logo} className="fr_logo_navbar" />}

                            </div>
                        </Nav.Link>
                    </Tooltip>

                    <NavBarLink icon={ic_event_available} iconSize={30} caption='Disponibilidades' hide={hide} path='/disponibilidades' />
                    <NavBarLink icon={profile} iconSize={hide? 30 : 30} caption='Prontuários' hide={hide} path='/prontuarios'/>
                    <NavBarLink icon={fileSymlinkFile} iconSize={hide? 30 : 30} caption='Encaminhamentos' hide={hide} path='/encaminhamentos'/>
                    <NavBarLink icon={iosPeople} iconSize={hide? 30 : 30} caption='Pacientes' hide={hide} path='/pacientes'/>
                    <NavBarLink icon={calendar} iconSize={hide? 30 : 30} caption='Agenda' hide={hide} path='/agenda'/>

                </Nav>

            </div>
        </NavContext.Provider>
    );
}

export default NavBar;