import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function RegRelatoAvulso(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboTerapeuta, setComboTerapeuta] = useState([{ value: '', display: '' }])
  const [comboMotivoAlta, setComboMotivoAlta] = useState([{ value: '', display: '' }])
  const [comboTipoRelatos, setComboTipoRelatos] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])
  const [enableEdit, setEnableEdit] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('prontuarios', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('prontuarios', 'Inclusao'))
      }
      setComboTerapeuta(await api.getComboData('terapeuta'))
      setComboTipoRelatos(await api.getComboData('tipo_relatos'))
      setComboMotivoAlta(await api.getComboData('motivo_alta'))
      
      if (query.pk_pro > 0) {
        console.log(auth)
        setUpdateType(true)
        let auxValues = {...data}
        auxValues.PK_PRO = query.pk_pro
        auxValues.FK_USU = getUserCode()
        api.get('/cadastro?pk_usu='+auth.user).then(r=>{
          console.log('oiiiiiiiiiiiiiiiiiii')
          auxValues.FK_CAD_TERAPEUTA = r.data[0].FK_CAD 
          console.log(auxValues, '------------------')
          setData(auxValues)
          swal.close()
        })
        
        // api.get('/prontuarios?pk_pro=' + query.codigo+'&usu_logado='+auth.user).then(r => {
        //   if (r.data[0]) {
        //     console.log(r.data[0])
        //     setData(r.data[0])
        //     swal.close()
        //   } else {
        //     swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
        //       .then((result) => {
        //         if (result.value) {
        //           window.history.back()
        //         }
        //       })
        //   }
        // })
      } else swal.close()
    }

    fetchData()
  }, [])

  useEffect(() => {
    let auxValues={...data}
    if (data.FK_TRE == 4){
      auxValues.FK_MAL = 4
    }
    setData(auxValues)
  }, [data.FK_TRE])

  function consistData(data) {
    let required = [ 
      'FK_TRE',

    ]
    
    if (data.FK_TRE == 4) {
      required.push('DATA_ALTA')
    }

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (fieldName == 'FK_TRE') fieldName = 'Tipo Relato'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (auxValues.FK_TRE == 4){
            procedimentoAlta(auxValues)
          } else {
            relatoAvulso(auxValues)
          }

        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function relatoAvulso(auxValues){
    if (updateType) {
      api.post('/prontuario_eletronico/relatoAvulso', auxValues).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
            html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: `<p style='color: ${colors.info900}'></p>`,
            icon: 'warning',
          })
        }
      })
    } else {
      try {
        api.post('/prontuario_eletronico/relatoAvulso', auxValues).then(r => {
          console.log(r.status)
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Adicionado',
              text: "O registro foi adicionado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.history.back()
              }
            })
          } else {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              text: "O registro não foi adicionado!",
              icon: 'warning',
            })
          }
        })
      } catch {
        swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          text: "O registro não foi adicionado!",
          icon: 'warning',
        })

      }

    }
  
  }

  function procedimentoAlta(auxValues){
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>PROCEDIMENTO DE ALTA DO PACIENTE</h2>`,
      html: `<p style='color:${colors.info900}'>Atenção!!! Todos os agendamentos deste prontuário serão excluídos da agenda. Deseja prosseguir?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then( r => {
    
      if (r.value) {
          if (updateType) {
            api.post('/prontuario_eletronico/relatoAvulso', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/prontuario_eletronico/relatoAvulso', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    console.log(e.target.value)
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Visualização de Prontuários</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='100'
            value={data.PK_PRO || ''}
            onChange={handleChange}
            name="PK_PRO"
            id="PK_PRO"
            hidden
            color="#389492"
            label="Nº Prontuário"
            variant="outlined"
            size="small"
            
            style={{ width: 330 }}
          />
          <FrTextInput
            hidden
            maxLength='100'
            value={data.FK_USU || ''}
            onChange={handleChange}
            name="FK_USU"
            id="FK_USU"
            color="#389492"
            label="Nº Prontuário"
            variant="outlined"
            size="small"
            
            style={{ width: 330 }}
          />
          <FrSelect
            value={data.FK_TRE || ''}
            style={{ width: 430 }}
            name="FK_TRE"
            id="FK_TRE"
            label='Tipo Relato'
            data={comboTipoRelatos}
            onChange={handleChange}
          /> 

<FrDatePicker
            value={(data.DATA_ATENDIMENTO || '')}
            name="DATA_ATENDIMENTO"
            id="DATA_ATENDIMENTO"
            color="#528b46"
            label="Data Atendimento"
            variant="outlined"
            size="small"
            onChange={handleChange}
            style={{ width: 190 }}
          />
          <FrSelect
            value={data.ATENDIMENTO_EXTERNO || ''}
            style={{ width: 190 }}
            name="ATENDIMENTO_EXTERNO"
            id="ATENDIMENTO_EXTERNO"
            label='Atendimento Externo'
            data={comboAtivo}
            onChange={handleChange}
          /> 
          <br />

          <FrTextInput
            maxLength='100'
            value={data.OBSERVACAO || ''}
            onChange={handleChange}
            name="OBSERVACAO"
            id="OBSERVACAO"
            color="#389492"
            label="Observação"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
          />
          <FrSelect
            value={data.FK_CAD_TERAPEUTA || ''}
            style={{ width: 430 }}
            name="FK_CAD_TERAPEUTA"
            id="FK_CAD_TERAPEUTA"
            label='Terapeuta Responsável Pelo Relato'
            data={comboTerapeuta}
            onChange={handleChange}
          />
          <br /> 
          {
            data.FK_TRE == 4 &&
          <FrDatePicker
            value={(data.DATA_ALTA || '')}
            name="DATA_ALTA"
            id="DATA_ALTA"
            color="#528b46"
            label="Data Alta"
            variant="outlined"
            size="small"
            onChange={handleChange}
            style={{ width: 190 }}
          />
        }
          {
            data.FK_TRE == 4 &&
              <FrSelect
              value={data.FK_MAL || ''}
              style={{ width: 430 }}
              name="FK_MAL"
              id="FK_MAL"
              disabled={data.FK_TRE == 4}
              label='Motivo'
              data={comboMotivoAlta}
              onChange={handleChange}
            /> 

          }
          <br/>

          {
            data.FK_TRE == 4 &&
          <FrTextField
              maxLength='1000'
              value={data.COMPLEMENTO || ''}
              onChange={handleChange}
              name="COMPLEMENTO"
              id="COMPLEMENTO"
              color="#389492"
              label="Complemento"
              variant="outlined"
              size="small"
              multiline={true}
              rows={3}
              style={{ width: '80%' }}
            />
          }

          


        </form>
        <div className="regButtonsDiv">
          {enableEdit ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{1==0 ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegRelatoAvulso;
