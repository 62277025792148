import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrameF2 from '../components/FrameF2'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getData } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const pageName='prontuarios'

const swal = withReactContent(Swal)

function Prontuarios(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [pac, setPac] = useState()
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ VALUE: 'S', DISPLAY: 'Ativo' }, { VALUE: 'N', DISPLAY: 'Inativo' }])
  const [comboPac, setComboPac] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])

  const columns = [
    { id: 'NUMERO_PRONTUARIO', numeric: false, label: 'Nº Prontuário', align: 'left', Width: 100 },
    { id: 'PACIENTE', numeric: false, label: 'Paciente', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos(pageName, 'Inclusao'))
      setenableEdit(await api.getAcessos(pageName, 'Alteracao'))
      setenableDel(await api.getAcessos(pageName, 'Exclusao'))

      setComboPac(await api.getComboData('pacientes', auth.user))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      query.usu_logado = auth.user
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        console.log(pageName)
        getData(event, setData, setFiltered, pageName)
      }
    }

    fetchData()
  }, [])

  function getExtraData(e) {
   return
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/intcc/prontuarios');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  useEffect(() => {
    const fetchAss = async () => {

      console.log(pac, 'pac aqui')
      setComboPac(await api.getComboData('pacientes', auth.user))

      const auxValues = { ...filter };
      if (pac > 0) {
        auxValues['fk_pac'] = pac;

        setFilter(auxValues);
      }
    }
    fetchAss()
  }, [pac])

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Prontuários</h3>
          <UserOptions  />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                  value={filter.usu_logado || ''}
                  style={{ width: 330 }}
                  name="usu_logado"
                  id="usu_logado"
                  color="#389492"
                  label="usu_logado"
                  variant="outlined"
                  size="small"
                  hidden
                  onChange={handleChange}
                />
              <div class="row" style={{marginLeft: '0px'}}>

                <FrSelect
                  value={filter.fk_pac || ''}
                  onChange={handleChange}
                  name="fk_pac"
                  id="fk_pac"
                  label='Pacientes'
                  data={comboPac}
                  style={{ width: 320 }}
                />
                <FrameF2 tipoModal={'Pacientes'} data={filter} pac={pac} setPac={setPac} ></FrameF2>
            
              </div>
              <FrTextInput
                  value={filter.prontuario || ''}
                  style={{ width: 330 }}
                  name="prontuario"
                  id="prontuario"
                  color="#389492"
                  label="Nº Prontuário"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                />
              <FrSelect
                value={filter.situacao || ''}
                style={{ width: 170 }}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboAtivo}
                onChange={handleChange}
              />
              

            </div>
            <div>
              <button onClick={(e) => getData(e, setData, setFiltered, pageName)}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/intcc/prontuarios/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName={pageName} columns={columns} searched={filtered} codeName="PK_PRO" page={pageName} data={data}  relatoAvuslo={'S'} enableEdit={enableEdit} enableDel={false} prontuarioEletronico={'S'} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Prontuarios;
