import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrameF2 from '../components/FrameF2'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getData } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const pageName='prontuario_eletronico'

const swal = withReactContent(Swal)

function ProntuarioEletronico(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [pac, setPac] = useState()
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])

  const columns = [
    { id: 'FORMATDATA_ATENDIMENTO', numeric: false, label: 'Data Atendimento', align: 'left', Width: 100, order:'DATA_ATENDIMENTO' },
    { id: 'NOMETPA', numeric: false, label: 'Tipo Agendamento', align: 'left', minWidth: 150 },
    { id: 'STR_TERAPEUTA', numeric: false, label: 'Terapeuta', align: 'left', minWidth: 150 },
    { id: 'RELATADO', numeric: false, label: 'Relatado', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos(pageName, 'Inclusao'))
      setenableEdit(await api.getAcessos(pageName, 'Alteracao'))
      setenableDel(await api.getAcessos(pageName, 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      query.usu_logado = auth.user
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        console.log(pageName)
        getData(event, setData, setFiltered, pageName)
      }
    }

    fetchData()
  }, [])

  function getExtraData(e) {
   return
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/intcc/prontuario_eletronico');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  useEffect(() => {
    console.log(data)
    console.log(data[0])
  }, [data])
 

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Prontuário Eletrônico</h3>
          <UserOptions  />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData} >
            <div>
            <FrTextInput
                value={filter.pk_pro || ''}
                style={{ width: 330 }}
                name="pk_pro"
                id="pk_pro"
                color="#389492"
                label="usu_logado"
                variant="outlined"
                size="small"
                hidden
                onChange={handleChange}
              />
              <FrTextInput
                value={data[0]?.PRONTUARIO || ''}
                style={{ width: 160 }}
                name="prontuario"
                id="prontuario"
                color="#389492"
                label="Nº Prontuário"
                variant="outlined"
                size="small"
                disabled
                onChange={handleChange}
              />
              <FrTextInput
                value={data[0]?.PACIENTE || ''}
                style={{ width: 430 }}
                name="PACIENTE"
                id="PACIENTE"
                color="#389492"
                label="Paciente"
                variant="outlined"
                size="small"
                onChange={handleChange}
                disabled
              />
              
              

            </div>
            <div hidden>
              <button onClick={(e) => getData(e, setData, setFiltered, pageName)}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/intcc/prontuario_eletronico/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            regName={pageName} 
            columns={columns} 
            searched={filtered} 
            columnToOrder={2} 
            ascDescOrder={'desc'} 
            codeName="PK_MOV" 
            page={pageName} 
            data={data} 
            enableEdit={enableEdit} 
            uploadArquivos={'N'} 
            downloadArquivos={'N'} 
            enableDel={false} 
          />
        </div>
        <div className="regButtonsDiv">
         
          <Link to="/intcc/prontuarios">
            <button >
              Voltar
            </button>
          </Link>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ProntuarioEletronico;
