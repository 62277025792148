// import logo from './logo.svg';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import { routes, fallbackRoute } from './routes'

function App() {

  const routeComponents = routes.map((route, key) => {
		console.log(routes)
		if (route.protected) {
			return <ProtectedRoute path={'/intcc' + route.path} exact={route.exact} component={route.component} />
		} else {
			return <Route path={'/intcc' + route.path} exact={route.exact} component={route.component} />
		}
	});

  return (
    <div className="App">
      <Router>
				<Switch>
					{routeComponents}
					<ProtectedRoute component={fallbackRoute} />
				</Switch>
			</Router>
    </div>
  );
}

export default App;
