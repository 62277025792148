import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { ic_launch } from 'react-icons-kit/md/ic_launch'
import {calendar} from 'react-icons-kit/oct/calendar'
import {profile} from 'react-icons-kit/icomoon/profile'
import {ic_event_available} from 'react-icons-kit/md/ic_event_available'
import Slide from '@material-ui/core/Slide';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import {fileSymlinkFile} from 'react-icons-kit/oct/fileSymlinkFile'
import FooterDelphus from '../components/FooterDelphus';
import api from '../services/api'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [atalhos, setAtalhos] = useState([

    {
      label: 'Disponibilidades',
      icon: ic_event_available,
      path: '/disponibilidades',
      color: "#ee403d"
    },
    {
      label: 'Prontuário',
      icon: profile,
      path: '/prontuarios',
      color: "#600c93"
    },

    {
      label: 'Encaminhamentos',
      icon: fileSymlinkFile,
      path: '/encaminhamentos',
      color: "#e98a1b"
    },

    {
      label: 'Pacientes',
      icon: iosPeople,
      path: '/pacientes',
      color: '#57b0ef'
    },
    {
      label: 'Agenda',
      icon: calendar,
      path: '/agenda',
      color: '#1eaea3'
    },

   
  ])

  return (
    <div className="Home">
      {/* {console.log(props)} */}
      <NavBar />
      <div className="HomeContent">
        <div className="defaultHeader">
          {/* <h3>Seja bem vindo(a), <strong>{auth.name}.</strong>Seu último login foi em {auth.ultimo_login}.</h3> */}
          <UserOptions  />
        </div>
        <br />
        <div className="atalhos">

          {/*////////////////////////// ADICIONAR OBJETOS NA CONST 'ATALHOS' SEGUINDO PADRÃO */}
          
          {atalhos.map(atalho => {
            return (
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href={"/intcc"+atalho.path} className="atalho" style={atalho.color ?  {backgroundColor: atalho.color}: {}} >
                <div className='iconeAtalho' >
                  <Icon icon={atalho.icon} size={atalho.size || 40} />
                  <strong>{atalho.label}</strong>

                </div>
              </Nav.Link>
            </Tooltip>)
          })}
          
          
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
