import { flexbox } from '@mui/system';
import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { userCircle } from 'react-icons-kit/fa/userCircle'
import { ic_exit_to_app } from 'react-icons-kit/md/ic_exit_to_app'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {calendar} from 'react-icons-kit/icomoon/calendar'
import {coinDollar} from 'react-icons-kit/icomoon/coinDollar'

import './index.css';

const swal = withReactContent(Swal)

function UserOptions(props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));

    function logout(e) {
        e.preventDefault();
        e.preventDefault();
        swal.fire({
            title: 'Deseja sair do sistema?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                localStorage['authIntcc'] = JSON.stringify({ authenticated: false, user: 0 })
                window.location.href = '/intcc/login'
            }
        })

    }

    function handleAlertaCheckin(e) {
        e.preventDefault()
        swal.fire('Atenção!', 'Você possui pacientes com checkin atrasado.', 'warning')
    }

    function handleAlertaComprovante(e) {
        e.preventDefault()
        swal.fire('Atenção!', 'Você possui atendimento(s) sem comprovante de pagamento.', 'warning')
    }


    console.log(auth.checkin, auth.comprovante)
    return (
        <div className="userOptions">
            <div className='userInfo'>
                <Icon icon={userCircle} size={24} style={{ marginRight: '5px', marginLeft: '5px' }} />
                <p className='userName'>{auth.name}</p>
            </div>
            <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
            {auth.checkin && <div onClick={handleAlertaCheckin} style={{margin: 2,display: 'flex', justifyContent: 'center', cursor: 'pointer'}}>
                    <div className='circuloAlerta'></div>
                    <Icon icon={calendar} size={20} />
                </div>
                }
            {auth.comprovante && <div onClick={handleAlertaComprovante} style={{margin: 2,display: 'flex', justifyContent: 'center', cursor: 'pointer'}}>
                    <div className='circuloAlerta'></div>
                    <Icon icon={coinDollar} size={20} />
                </div>}
            <button onClick={logout} className='logoutButton'>
                <Icon icon={ic_exit_to_app} size={24} />
            </button>
            </div>
        </div>
    );
}

export default UserOptions;