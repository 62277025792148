import $ from 'jquery'
import api from '../services/api'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrLoading from '../components/FrLoading'


const swal = withReactContent(Swal)

export function paramsToObject(entries) {
    let result = {}
    for (let entry of entries) { // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        // console.log(key, value)
        if (value.indexOf('[') > -1 && value.indexOf(']') > -1) {
            result[key] = JSON.parse(value)
        } else result[key] = value;
    }
    return result;
}

export function setMultiSelectFilter(filter, data) {
    return new Promise((resolve, reject) => {
        let result = []
        if (filter.length > 0) {
            data.map((item) => {
                if (filter.indexOf(item.value) > -1) return result.push(item)
            })
            resolve(result)
        }
        else resolve(null)
    })
}

export function getUserCode() {
    let auth = JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 }))
    return auth.user
}

export function formToObject(formData) {
    let queryObject = {}
    formData.forEach(function (value, key) {
        if (value !== null && value !== '') {
            queryObject[key] = value;
        }
    });
    return queryObject
}

export function objectToQueryString(obj) {
    var str = Object.keys(obj).map(function (key) {
        if (Array.isArray(obj[key])) {
            console.log(obj[key])
            return key + '=[' + obj[key].map((item) => {
                return item.value
            }).join(',') + ']'
        }
        else if (obj[key]) return key + '=' + obj[key];
    }).join('&');
    return str
}

export function removeSpecialChar(value) {
    return value.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toUpperCase()
}

export function onlyNumbers(value) {
    return (value || '').replace(/[^\d]+/g, '');
}

export function onBlurDecimal(e, qty, handleChange) {
    e.preventDefault();
    console.log(e.target.value)
    let value = e.target.value.replace(".", "").replace(",", ".")
    value = Number(value).toFixed(qty).replace(".", ",")
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function onBlurSeparator(e, handleChange) {
    e.preventDefault();
    console.log(e.target.value)
    let value = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    console.log(value)
    e.target.value = value
    handleChange(e)
}

export function validarCNPJ(cnpj) {
    cnpj = onlyNumbers(cnpj)

    if (cnpj === '') return false;

    console.log(cnpj)

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1))
        return false;

    return true;

}

export function validarCPF(cpf) {
    cpf = onlyNumbers(cpf)

    if (cpf === '') return false;
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
}

export function populateForm(frm, data) {
    $.each(data, function (key, value) {
        var $ctrl = $('[name=' + key + ']', frm);
        if ($ctrl.is('select')) {
            $("option", $ctrl).each(function () {
                // eslint-disable-next-line
                if (this.value === value) { this.selected = true; }
            });
        }
        else {
            // eslint-disable-next-line
            switch ($ctrl.attr("type")) {
                case "text": case "hidden": case "textarea": case "date":
                    $ctrl.val(value);
                    break;
                case "radio": case "checkbox":
                    $ctrl.each(function () {
                        // eslint-disable-next-line
                        if ($(this).attr('value') === value) { $(this).attr("checked", value); } else { $(this).attr("checked", null); }
                    });
                    break;
            }
        }
    });
};

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}


export function horaFinal(horaString, intervaloString) {
    var resultado = moment(horaString, 'hh:mm').add(intervaloString, 'minutes').format('hh:mm')
    return resultado
}


export function getFullAge(dateString) {
    var birthDate = new Date(dateString);
    let hoje = new Date();
    let dia_hoje = hoje.getDay();
    let mes_hoje = hoje.getMonth() + 1;
    let ano_hoje = hoje.getYear();

    let dia = birthDate.getDay();
    let mes = birthDate.getMonth() + 1;
    let ano = birthDate.getYear();


    let diferenca_dia = dia_hoje - dia;
    let diferenca_mes = mes_hoje - mes;
    let diferenca_ano = ano_hoje - ano;
    //alert(diferenca_dia+" "+diferenca_mes+" Ano = "+diferenca_ano+" ")
    if (diferenca_dia < 0) {
        diferenca_dia = 30 + diferenca_dia;
        diferenca_mes--;
    } else if (diferenca_mes < 0) {
        diferenca_mes = 12 + diferenca_mes;
        diferenca_ano--;
    }

    return diferenca_ano + ' anos ' + diferenca_mes + " meses " + diferenca_dia + ' dias';

}


export function pegaDia(data, soData) {
    let dia = data.getDate();
    if (dia < 10)
        dia = '0' + dia
    let mes = data.getMonth() + 1;
    if (mes < 10)
        mes = '0' + mes
    let ano = data.getFullYear();
    let diaSemana = data.getDay();
    if (diaSemana == 0)
        diaSemana = 'Domingo'
    else
        if (diaSemana == 1)
            diaSemana = 'Segunda-feira'
        else
            if (diaSemana == 2)
                diaSemana = 'Terça-feira'
            else
                if (diaSemana == 3)
                    diaSemana = 'Quarta-feira'
                else
                    if (diaSemana == 4)
                        diaSemana = 'Quinta-feira'
                    else
                        if (diaSemana == 5)
                            diaSemana = 'Sexta-feira'
                        else
                            if (diaSemana == 6)
                                diaSemana = 'Sábado'
    if (soData)
        return dia + '/' + mes + '/' + ano
    else
        return dia + '/' + mes + '/' + ano + '     -     ' + diaSemana


}

export function dateSql(texto, entre) {
    let data = ''
    if (texto != null) {
        if (typeof entre !== 'undefined') {
            data = entre + texto.substr(0, 4) + '-' + texto.substr(5, 2) + '-' + texto.substr(8, 2) + entre
        } else {
            data = texto.substr(0, 4) + '-' + texto.substr(5, 2) + '-' + texto.substr(8, 2)
        }
    }
    return data;
}

function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ''));
  }

export function getData(e, setData, setFiltered, page, callback, modal, filter) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    console.log(filter)
    if(filter) queryObject = {...filter}
    // if (!isEmpty(queryObject)) {
    if (true) {
        console.log(queryObject)
        queryObject.buscar = true
    //   setFiltered(true)
        if (!modal) {
            window.history.replaceState({ filtered: true }, 'filter', "/intcc/"+page+"?" + objectToQueryString(queryObject));
            swal.fire({
            html: <FrLoading text="Buscando dados..." />,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
            })
        }
        api.get('/'+page, { params: (modal ? removeEmpty(filter) : queryObject)}).then(r => {
        console.log(r.data)
        if (!modal) swal.close()
        if (callback) {
            callback()
        }
            setData(r.data)
        })
    } else {
        swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
   
}

function substituiAcentos(text){
    let newText = text

    newText = newText.replaceAll(`\\'E7`, "Ç");
    newText = newText.replaceAll(`\\'C7`, "Ç");

    newText = newText.replaceAll(`\\'E0`, "À");
    newText = newText.replaceAll(`\\'E1`, "Á");
    newText = newText.replaceAll(`\\'E2`, "Â");
    newText = newText.replaceAll(`\\'E3`, "Ã");
    newText = newText.replaceAll(`\\'C0`, "À");
    newText = newText.replaceAll(`\\'C1`, "Á");
    newText = newText.replaceAll(`\\'C2`, "Â");
    newText = newText.replaceAll(`\\'C3`, "Ã");

    newText = newText.replaceAll(`\\'F3`, "Ó");
    newText = newText.replaceAll(`\\'F4`, "Ô");
    newText = newText.replaceAll(`\\'F5`, "Õ");
    newText = newText.replaceAll(`\\'D3`, "Ó");
    newText = newText.replaceAll(`\\'D4`, "Ô");
    newText = newText.replaceAll(`\\'D5`, "Õ");

    newText = newText.replaceAll(`\\'E9`, "É");
    newText = newText.replaceAll(`\\'EA`, "Ê");
    newText = newText.replaceAll(`\\'C9`, "É");
    newText = newText.replaceAll(`\\'CA`, "Ê");

    newText = newText.replaceAll(`\\'DA`, "Ú");
    newText = newText.replaceAll(`\\'FA`, "Ú");

    newText = newText.replaceAll(`\\'ED`, "Í");


    newText = newText.replaceAll(`\\'BA`, "º");

    return newText
}

export function convertRtfToHtml(richText) {
    if (!(typeof richText === "string" && richText)) {
      return null;
    }
    var html =richText
    var i = html.indexOf(`{\\*\\generator WPTools_5.370;}`) > -1 ? html.indexOf(`{\\*\\generator WPTools_5.370;}`)+29 : 0
    if (i == 0)
        i = html.indexOf(`{\\*\\GENERATOR WPTOOLS_5.370;}`) > -1 ? html.indexOf(`{\\*\\GENERATOR WPTOOLS_5.370;}`)+29 : 0
    
    // if(html.indexOf(`{\\*\\generator WPTools_5.370;}`) > -1)
    // if(html.indexOf(`{\\*\\GENERATOR WPTOOLS_5.370;}`) > -1)
    //     html= html.substring(html.indexOf(`{\\*\\GENERATOR WPTOOLS_5.370;}`)+29, html.length-1);
    
    html = html.substring(i, html.length-1)

    if(html.indexOf(`DEFAULT PARAGRAPH FONT;}`) > -1)
        html = html.substring(html.indexOf(`DEFAULT PARAGRAPH FONT;}`)+27, html.length-1);

    if(html.indexOf(`default paragraph font;}`) > -1)
        html = html.substring(html.indexOf(`default paragraph font;}`)+27, html.length-1);
    
    html = substituiAcentos(html)
    

  
    
    html = html.replaceAll('\\TAB', '    ')
    html = html.replaceAll('\\tab', '    ')

    html = html.replaceAll(`{\\PLAIN`, '<p>')
    html = html.replaceAll(`{\\plain`, '<p>')
    html = html.replaceAll('\\F1', '')
    html = html.replaceAll('\\f1', '')
    html = html.replaceAll('\\FS22', '')
    html = html.replaceAll('\\fs22', '')
    html = html.replaceAll('\\CF0', '')
    html = html.replaceAll('\\cf0', '')
    html = html.replaceAll('\\CB0', '')
    html = html.replaceAll('\\cb0', '')
    html = html.replaceAll('\\F2', '')
    html = html.replaceAll('\\f2', '')
    html = html.replaceAll('\\FI720', '    ')
    html = html.replaceAll('\\fi720', '    ')
    html = html.replaceAll('\\SL276', '')
    html = html.replaceAll('\\sl276', '')
    html = html.replaceAll('\\SLMULT1', '')
    html = html.replaceAll('\\slmult1', '')
    html = html.replaceAll('\\QJ', '')
    html = html.replaceAll('\\qj', '')
    html = html.replaceAll('\\\'93', '"')
    html = html.replaceAll('\\\'94', '"')
    

    
    html = html.replaceAll('\\PARD', '</br>')
    html = html.replaceAll('\\pard', '</br>')
    html = html.replaceAll('\\PAR', '</br>')
    html = html.replaceAll('\\par', '</br>')
    html = html.replaceAll('\\LINE', '</br>')
    html = html.replaceAll('\\line', '</br>')
    
    html = html.replaceAll('\\PLAIN', '')
    html = html.replaceAll('\\plain', '')

    
    html = html.replaceAll('\\B0', '</b>')
    html = html.replaceAll('\\b0', '</b>')
    html = html.replaceAll('\\B', '<b>')
    html = html.replaceAll('\\b', '<b>')

    if (html[html.length-1] == '\\'){
        html = html.replace('\\', '')
    }

    if (html[html.length-1] == '}'){
        html = html.replace('}', '</p>')
    }
    // html = html.substring(html.indexOf(`{\\*\\GENERATOR WPTOOLS_5.370;}`, html.length-1))
    // html = html.substring(619,html.length)
    // Reverse the richText to HTML conversions
    // html = html.replace(/"{\\PARD \\BRDRB \\BRDRS \\BRDRW10 \\BRSP20 \\PAR}{\\PARD\\PAR}"/gi, "<hr/>");
    // html = html.replace(/"{\\PARD\\PAR}"/gi, "<br/>");
    // html = html.replace(/"{\\PARD\\PAR}"/gi, "<p></p>");
    // html = html.replace(/"{\\B"/gi, "<b>");
    // html = html.replace(/"{\\I"/gi, "<i>");
    // html = html.replace(/"{\\UL"/gi, "<u>");
    // html = html.replace(/"{\\STRIKE"/gi, "<strike>");
    // html = html.replace(/"{\PLAIN"/gi, "");
    // html = html.replace(/"{\\SUPER"/gi, "<sup>");
    // html = html.replace(/"{\\SUB"/gi, "<sub>");
    // html = html.replace(/"{\\PARD"/gi, "<p>");
    // html.replace(`\\PAR}`, "</p>");
    // html = html.replace(/"}"/gi, "</b></i></u></strike></sup></sub>");
  
    // // Reverse hyperlink conversion
    // html = html.replace(/{{{\n/gi, "<a>");
    // html = html.replace(/{{{\\FIELD{\\*\\FLDINST{HYPERLINK\n "([^"]+)"\n}}{\\FLDRSLT{\\UL\\CF1\n/gi, "<a href=\"$1\">");
    // html = html.replace(/\n}}}/gi, "</a>");
  
    // // Strip any other remaining RTF tags
    // html = html.replace(/{\\RTF1\\ANSI\n/gi, "");
    // html = html.replace(/{\\COLORTBL\n;\n\\RED0\\GREEN0\\BLUE255;\n}\n/gi, "");
  
    return html;
  }
