import React, { useState, useEffect } from 'react';

import './styles/Login.css';
import api from '../services/api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from '../imgs/logo.png';
import FrDatePicker from '../components/FrDatePicker'
import FrTextInput from '../components/FrTextInput'
import delphus from '../imgs/delphus.png';

const swal = withReactContent(Swal)


function ForgotPw() {

    const [data, setData] = useState({})
 

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        // const data = new FormData(form);

        if (data.email === '') {
            swal.fire('Informe seu email de login para receber o link de mudança de senha!', 'Informe o email.', 'warning')
            return
        }

        //Converte FormData em JSON
        var object = {};

       

        //Checa no servidor
        api.post('usuarios/reset_password', {email: data.email, cpf: data.cpf, data_nascimento: data.data_nascimento, host: window.location.href})
            .then(function (response) {
                console.log(response.data[0])
                let { pk_usu } = response.data[0];
                if (pk_usu > 0) {
                    // swal.fire('O link para mudança de senha foi enviado para o seu email!', '', 'success')
                    swal.fire('O link para mudança de senha foi enviado para o seu email!', '','success')
                    .then((result) => {
                      if (result.value) {
                        window.location.href = '/celg/login' 
                    }
                    })                    
                } else {
                    swal.fire('Usuário não localizado!', '', 'error')
                }
            })
            .catch(function (error) {
                swal.fire('Usuário não localizado!', '', 'error')
            });
    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        // auxValues[e.target.name] = e.target.value;
        console.log(e.target.value)
        switch (e.target.name) {
          default: auxValues[e.target.name] = e.target.value;
        }
        setData(auxValues);
      };

    return (
        <div className="Login">
            <div className="form">
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <img alt="logo" src={logo} title="intcc" className="logo"/>
                    <div className="form-label-group">
                        <FrTextInput
                            maxLength='100'
                            value={data.cpf || ''}
                            onChange={handleChange}
                            name="cpf"
                            id="cpf"
                            color="#389492"
                            label="CPF"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 180 }}
                        />
                        <br/>
                        <FrDatePicker
                          value={(data.data_nascimento || '').split('T')[0]}
                          name="data_nascimento"
                          id="data_nascimento"
                          color="#528b46"
                          label="Data Nascimento"
                          variant="outlined"
                          onChange={handleChange}
                          size="small"
                          style={{ width: 180 }}
              
                        />
                        <br/>
                        <FrTextInput
                            maxLength='100'
                            value={data.email || ''}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            color="#389492"
                            label="E-mail"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 180 }}
                        />
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>OK</p></span>
                    </button>
                </form>
            </div>
            {/* <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div> */}
        </div>
    );

}

export default ForgotPw;
