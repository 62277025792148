import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from '../services/api'
import { paramsToObject, convertRtfToHtml } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function RegProntuarios(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [editorState, setEditorState] = useState({})
  const [comboTerapeuta, setComboTerapeuta] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [enableEdit, setEnableEdit] = useState('S')
  const [pkMov, setPkMov] = useState()

  
  let sampleMarkup = ''
  let contentHTML = convertFromHTML(sampleMarkup)
  let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
  const [content, setContent] = useState(JSON.stringify(convertToRaw(state)))
  const [content2, setContent2] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      // if (query.codigo > 0) {
      //   setEnableEdit(await api.getAcessos('prontuarios', 'Alteracao'))
      // } else {
      //   setEnableEdit(await api.getAcessos('prontuarios', 'Inclusao'))
      // }

      
      setComboTerapeuta(await api.getComboData('terapeuta'))

      if (query.codigo > 0) {
        setPkMov(query.codigo)
        setUpdateType(true)
        api.get('/prontuario_eletronico/relato?pk_mov=' + query.codigo).then(r => {
          if(r.data){
            if (r.data[0]) {
              let sampleMarkup = convertRtfToHtml(r.data)
              console.log(sampleMarkup)
              
              let contentHTML = convertFromHTML(sampleMarkup)
              let state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
              setContent(JSON.stringify(convertToRaw(state)))
              setData({RELATO: r.data})
              api.get('/prontuario_eletronico/permite_edicao?pk_mov='+query.codigo).then(res=> {
                if (res.data[0].RELATADO === 'SIM'){
                  setEnableEdit('N')
                }
                swal.close()
              })
            } else {
              setData({RELATO: ''})
              swal.close()
              // swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              //   .then((result) => {
              //     if (result.value) {
              //       window.history.back()
              //     }
              //   })
            }
          }else {
            setData({RELATO: ''})
            swal.close()
            // swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
            //   .then((result) => {
            //     if (result.value) {
            //       window.history.back()
            //     }
            //   })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        auxValues.relato = stateToHTML(editorState.getCurrentContent())
        auxValues.pk_mov = pkMov
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/prontuario_eletronico/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/prontuarios/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Visualização de Prontuários</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
        <ThemeProvider theme={myTheme}>
          <MUIRichTextEditor
              onChange={value => {
                const content = JSON.stringify(
                  convertToRaw(value.getCurrentContent())
                );
                setContent2(content);
                setEditorState(value)
              }}
              readOnly={enableEdit!=='S'}
              controls={["bold", "italic", "underline", "undo", "redo", "numberList", "bulletList"]} 
              defaultValue={content} 
              data={data.RELATO} 
              name="relato" 
              id="relato" />

        </ThemeProvider>

          


        </form>
        <div className="regButtonsDiv">
          {enableEdit == 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{1==0 ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegProntuarios;
