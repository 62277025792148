import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableReg from '../components/FrTableReg'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrameF2 from '../components/FrameF2'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getData, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const pageName='pacientes'

const swal = withReactContent(Swal)

function Pacientes(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [pac, setPac] = useState()
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboAtivo, setComboAtivo] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])
  const [pk_pac ,setPk_pac] = useState([])
  const [showModal, setShowModal] = useState(false)

  const columns = [
    { id: 'NOME', numeric: false, label: 'Paciente', align: 'left', Width: 100 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos(pageName, 'Inclusao'))
      setenableEdit(await api.getAcessos(pageName, 'Alteracao'))
      setenableDel(await api.getAcessos(pageName, 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      query.usu_logado = auth.user
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        console.log(pageName)
        getData(event, setData, setFiltered, pageName, null, props.modal, query)
      }
    }

    fetchData()
  }, [])

  function getExtraData(e) {
   return
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  

  return (
    <div className="Manut">
      {!props.modal && <NavBar />}
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Pacientes</h3>
          {!props.modal && <UserOptions  />}
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
           
            <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#389492"
                label="Paciente"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

            <FrTextInput
                value={getUserCode() || ''}
                style={{ width: 330 }}
                name="usu_logado"
                id="usu_logado"
                hidden
                color="#389492"
                label="Paciente"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
             
             <FrTextInput
                value={filter.cpf || ''}
                style={{ width: 330 }}
                name="cpf"
                id="cpf"
                color="#389492"
                label="CPF"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

            <FrSelect
              value={filter.status || ''}
              style={{ width: 140 }}
              name="status"
              id="status"
              label='Ativo'
              data={comboAtivo}
              onChange={handleChange}
            />
            
              

            </div>
            <div>
              <button onClick={(e) => getData(e, setData, setFiltered, pageName, null, props.modal, filter)}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          {props.modal ?
            <FrTableReg 
              setShowModal={setShowModal}
              escondeModal={props.escondeModal}
              visualizar={props.modal ? 'N' : 'S'}
              setpk_pac={setPk_pac}
              editar={false}
              f2={true}
              pac={props.pac}
              setPac={props.setPac}
              regName="pacientes"
              columns={columns}
              searched={filtered}
              codeName="PK_PAC"
              page={'Pacientes'}
              data={data}
              enableEdit={false}
              enableDel={false}
            />
            :
            <FrTable 
              regName={pageName} 
              columns={columns} 
              searched={filtered} 
              columnToOrder={0} 
              ascDescOrder={'asc'} 
              codeName="PK_PAC" 
              visualizar
              page={pageName} 
              data={data} 
              uploadArquivos={'N'} 
              downloadArquivos={'N'} 
              enableEdit={false}
              enableDel={false}
            />
          }
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Pacientes;
