import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';


const CssTextField = withStyles({
  root: {
    '& .MuiInputLabel-formControl': {
      top: '-2px'
    },
    '& label.Mui-focused': {
      color: '#FF6900',


    },
    '& .MuiInput-underline:after': {
      borderColor: '#FF6900',
      borderRadius: '1'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FF6900',
        borderRadius: '1'
      },
      '&:hover fieldset': {
        borderColor: '#FF6900',
        borderRadius: '1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF6900',
        borderRadius: '1'
      },
      height: '30px',
      maxHeight: '30px'
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },
}));

export default function CustomizedInputs(props) {
  const classes = useStyles();

  return (
    <>
      <CssTextField
        className={classes.margin}
        label={props.label}
        variant={props.variant}
        id={props.id}
        size={props.size}
        name={props.name}
        autoFocus={false}
        inputProps={{
          maxLength: props.maxLength,
          pattern: props.pattern,
        }}
        InputProps={{
          startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : '',
          endAdornment: props.endAdornment ? <InputAdornment position="end">{props.endAdornment}</InputAdornment> : '',
        }}
        {...props}
      />
    </>
  );
}