import Login from '../pages/Login';
import Home from '../pages/Home';
import ForgotPw from '../pages/ForgotPw';
import ChangePw from '../pages/ChangePw';
import Pacientes from '../pages/Pacientes';
import Prontuarios from '../pages/Prontuarios';
import ProntuarioEletronico from '../pages/ProntuarioEletronico';
import RegProntuarioEletronico from '../pages/RegProntuarioEletronico';
import RegProntuarios from '../pages/RegProntuarios';
import RegPacientes from '../pages/RegPacientes';
import Encaminhamentos from '../pages/Encaminhamentos';
import RegEncaminhamentos from '../pages/RegEncaminhamentos';
import RegRelatoAvulso from '../pages/RegRelatoAvulso';
import RegDisponibilidades from '../pages/RegDisponibilidades';
import Agenda from '../pages/Agenda';

export const routes = [
    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    
    {
        path: '/prontuarios',
        component: Prontuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'prontuarios',
        caption: 'Prontuários',
    },
    {
        path: '/prontuario_eletronico',
        component: ProntuarioEletronico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'prontuarios',
        caption: 'Prontuários',
    },
    {
        path: '/prontuario_eletronico/registro',
        component: RegProntuarioEletronico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'prontuarios',
    },
    {
        path: '/prontuarios/registro',
        component: RegProntuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/disponibilidades',
        component: RegDisponibilidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/prontuarios/relato_avulso/registro',
        component: RegRelatoAvulso,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/encaminhamentos',
        component: Encaminhamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'prontuarios',
        caption: 'Prontuários',
    },
    {
        path: '/encaminhamentos/registro',
        component: RegEncaminhamentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/pacientes',
        component: Pacientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'pacientes',
        caption: 'Pacientes',
    },
    {
        path: '/pacientes/registro',
        component: RegPacientes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/agenda',
        component: Agenda,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'agenda',
        caption: 'Agenda',
    },
   
];

export const fallbackRoute = Home