import {useContext} from 'react'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import Nav from 'react-bootstrap/Nav'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import { Icon } from 'react-icons-kit'
import { NavContext } from '../NavBar';

export function NavBarLink(props){
    const {hide} = useContext(NavContext)
    console.log(hide)
    return (
        <Tooltip title={props.hint || props.caption}>
            <Nav.Link href={"/intcc"+props.path} className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                <Icon icon={props.icon || ic_chevron_right} size={props.iconSize || 15} />
                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{props.caption}</strong>}
            </Nav.Link>
        </Tooltip>
    )
}